<template>
  <div>
    <img @click="backPage" style="width: 100%" :src="buildingImg">
  </div>
</template>

<script>
import conf from "@/utils/conf"
export default {
name: "ErrorPage",
  data(){
    return {
      buildingImg:conf.getBuildingImg()
    }
  },
  methods: {
    backPage(){
      this.$router.replace('home')
    }
  }
}
</script>

<style scoped>

</style>